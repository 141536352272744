<template>
  <div class="pb-5 container">
    <b-alert
      :show="hasAlert()"
      variant="danger"
      class="mb-4">
      <div class="text-center">
        {{ alertMessage }}
      </div>
    </b-alert>
    <form>
      <!-- Credit card details -->
      <div class="row">
        <div v-if="!ltiRedirect" class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7 mb-3 mb-md-0">
          <h5 class="mb-3">
            Payment method
          </h5>
          <b-card no-body>
            <b-card-header :class="{'pointer': isEnrollmentKey()}" @click="changePaymentType('card')">
              <font-awesome-icon
                v-if="isCardPayment()"
                :icon="'dot-circle'"
                fixed-width
                class="text-primary" />
              <font-awesome-icon
                v-else
                :icon="['far', 'circle']"
                fixed-width
                class="font-weight-semibold" />
              <span class="font-weight-semibold">Credit card</span>
            </b-card-header>
            <b-collapse id="cc-collapse" :visible="isCardPayment()">
              <b-card-body>
                <div class="form-group">
                  <label for="card_number" :class="{'text-danger': errors.has('card_number')}">Card number
                    <span v-show="errors.has('card_number')"><font-awesome-icon icon="exclamation-triangle" size="sm" fixed-width /></span>
                  </label>
                  <input
                    id="card_number"
                    key="card_number"
                    v-model="card.number"
                    v-validate="'required|credit_card'"
                    data-vv-validate-on="blur|change"
                    :class="{'is-invalid': errors.has('card_number')}"
                    class="form-control w-100"
                    type="text"
                    name="card_number">
                </div>
                <div class="form-row">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="form-group">
                      <label for="expDate" :class="{'text-danger': errors.has('exp_month') || errors.has('exp_year')}">Expiration
                        <span v-show="errors.has('exp_month') || errors.has('exp_year')"><font-awesome-icon icon="exclamation-triangle" size="sm" fixed-width /></span>
                      </label>
                      <div class="form-row">
                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <b-form-select
                            id="exp_month"
                            key="exp_month"
                            v-model="card.exp_month"
                            v-validate="'required|numeric|max:2'"
                            data-vv-validate-on="blur|change"
                            :class="{'is-invalid': errors.has('exp_month')}"
                            class="form-control w-100 py-1"
                            placeholder="MM"
                            name="exp_month">
                            <option selected disabled value>
                              MM
                            </option>
                            <option v-for="month in months" :key="month.m">
                              {{ month.m }}
                            </option>
                          </b-form-select>
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <b-form-select
                            id="exp_year"
                            key="exp_year"
                            v-model="card.exp_year"
                            v-validate="'required|numeric|max:4'"
                            data-vv-validate-on="blur|change"
                            :class="{'is-invalid': errors.has('exp_year')}"
                            class="form-control w-100 py-1"
                            name="exp_year">
                            <option selected disabled value>
                              YYYY
                            </option>
                            <option v-for="year in years" :key="year.y" v-bind="years">
                              {{ year.y }}
                            </option>
                          </b-form-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="form-group">
                      <label for="cvc" :class="{'text-danger': errors.has('cvc')}">CVC
                        <span v-show="errors.has('cvc')"><font-awesome-icon icon="exclamation-triangle" size="sm" fixed-width /></span>
                      </label>
                      <input
                        id="cvc"
                        key="cvc"
                        v-model="card.cvc"
                        v-validate="'required|numeric|max:4'"
                        data-vv-validate-on="blur|change"
                        :class="{'is-invalid': errors.has('cvc')}"
                        class="form-control w-100"
                        type="text"
                        name="cvc">
                    </div>
                  </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="border-top-0">
            <b-card-header :class="{'pointer': isCardPayment()}" @click="changePaymentType('enrollment_key')">
              <font-awesome-icon
                v-if="isEnrollmentKey()"
                :icon="'dot-circle'"
                fixed-width
                class="text-primary" />
              <font-awesome-icon
                v-else
                :icon="['far', 'circle']"
                fixed-width
                class="font-weight-semibold" />
              <span class="font-weight-semibold">Enrollment key</span>
            </b-card-header>
            <b-collapse id="ek-collapse" :visible="isEnrollmentKey()">
              <b-card-body>
                <div class="alert alert-secondary text-center" role="alert">
                  <span style="font-size: 10pt;">An enrollment key is a unique number provided by your instructor or bookstore at the time of purchase that gives you direct access to your courses with SolidProfessor</span>
                </div>
                <!-- Enrollment key -->
                <div class="form-group">
                  <label for="enrollment_key" :class="{'text-danger': errors.has('enrollment_key')}">Enter your enrollment key
                    <span v-if="errors.has('enrollment_key')"><font-awesome-icon icon="exclamation-triangle" size="sm" fixed-width /></span>
                  </label>
                  <div class="input-group w-100" :class="{'text-danger': errors.has('enrollment_key')}">
                    <input
                      id="enrollment_key"
                      key="enrollment_key"
                      v-model="form.enrollmentKey"
                      v-validate="'required|keyUnused'"
                      data-vv-validate-on="''"
                      :disabled="enrollmentKeyApplied"
                      :class="{'is-invalid': errors.has('enrollment_key')}"
                      class="form-control text-uppercase"
                      name="enrollment_key"
                      type="text"
                      style="padding-right:32px">
                    <font-awesome-icon v-if="enrollmentKeyApplied" class="text-primary" icon="check"
                                       style="position: absolute; right: 95; margin-top: 10px; z-index: 99;" />
                    <div class="input-group-append">
                      <b-button v-if="!enrollmentKeyApplied" type="button" variant="primary"
                                @click="validateEnrollmentKey()">
                        Apply
                      </b-button>
                      <b-button v-if="enrollmentKeyApplied" type="button" variant="outline-primary"
                                @click="removeEnrollmentKey">
                        Remove
                      </b-button>
                    </div>
                  </div>
                  <div class="text-danger">
                    <small>{{ errors.first('enrollment_key') }}</small>
                  </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <!-- Order details -->
        <div v-if="!ltiRedirect" class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5 card">
          <div class="card-body">
            <div class="text-center">
              <font-awesome-icon icon="rocket" size="2x" />
              <div v-if="user && user.first_name && user.last_name">
                <h5>Order Summary</h5>
                <span v-if="isLTIUser" class="d-block text-sm-left text-truncate">{{ first_name || user.first_name }} {{ last_name || user.last_name }}</span>
                <span v-else class="d-block text-sm-left text-truncate">{{ user.first_name }} {{ user.last_name }}</span>
                <span v-if="school" class="d-block text-sm-left text-truncate">{{ school.company }}</span>
                <span class="d-block text-sm-left text-truncate">{{ email }}</span>
              </div>
            </div>
            <!-- Inital plan price -->
            <div class="row py-3">
              <div class="col-12">
                <span><b>Student Membership</b></span>
              </div>
            </div>
            <div>
              <!-- Line items -->
              <div class="row pb-3">
                <div class="col-9">
                  <small>{{ school.term_in_years }}-year Student Membership to SolidProfessor</small>
                </div>
                <div class="col-3 text-right">
                  <small v-if="enrollment_price">{{ enrollment_price | formatCurrency }}</small>
                </div>
              </div>
              <div v-if="enrollmentKeyApplied" class="row pb-3">
                <div class="col-9">
                  <small>Applying enrollment key <span class="text-uppercase">{{ form.enrollmentKey }}</span></small>
                </div>
                <div class="col-3 text-right">
                  <small v-if="enrollment_price">-{{ enrollment_price | formatCurrency }}</small>
                </div>
              </div>
              <!-- Order total -->
              <div class="row pt-3 border-top">
                <div class="col-6">
                  <small><b>Total:</b></small>
                </div>
                <div class="col-6 text-right">
                  <small><b>{{ orderTotal | formatCurrency }}</b></small>
                </div>
              </div>
              <div v-if="isCardPayment()" class="text-center mt-4">
                <b-button v-if="isFormFilled() && !isFormDirty && !hasAlert()"
                          type="submit" :variant="hasAlert() ? 'danger' : 'primary'"
                          :disabled="!isFormFilled() || isFormDirty || hasAlert()"
                          @click="submit">
                  <span>Buy Now</span>
                </b-button>
                <b-button v-if="!isFormFilled() || isFormDirty || hasAlert()"
                          type="submit" variant="primary"
                          :disabled="!isFormFilled()"
                          @click="validateCardForm">
                  <span>Validate Card</span>
                </b-button>
              </div>
              <div v-if="isEnrollmentKey()" class="text-center mt-4">
                <b-button type="submit" variant="primary"
                          :disabled="!enrollmentKeyApplied"
                          @click="submit">
                  <span>Buy Now</span>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import store from '@/store';
import PaymentService from '@/utils/payment.js';
import FixedAlert from '@/components/FixedAlert';
import FullPageLoader from '@/utils/mixins/FullPageLoader';
import moment from 'moment';
import redirectMixin from '@/utils/mixins/Redirect';

export default {
  name: 'Payment',
  components: {FixedAlert},
  mixins: [FullPageLoader, redirectMixin],
  data () {
    return {
      // This is the ID of the individual sendgrid email that pertains to the Password Reset Process
      sendgridEmailID: 'd-9638310208bf45b88896c62995a543b9',
      enrollmentKeyApplied: false,
      purchaseType: 'enrollment_key',
      ltiRedirect: false,
      card: {
        number: null,
        exp_month: null,
        exp_year: null,
        cvc: null
      },
      form: {
        enrollmentKey: ''
      },
      alertMessage: null,
      path: null,
      validatedStripeCard: null,
    };
  },
  beforeRouteEnter (to, from, next) { // route guard - must have completed previous step, LTI users are exempt
    if (!store.getters['studentStore/getSelectedSchool'] && !store.getters['studentStore/getIsLTIUser']) {
      next({name: 'StudentStore'});
    } else {
      next();
    }
  },
  beforeRouteLeave (to, from, next) {
    // TODO: WTF?
    if (to.name === 'StudentConfirmation' || to.name === 'StudentPayment') {
      next();
    } else {
      // Redirect to self then move along to next? I am confused.
      next({name: 'StudentPayment'});
    }
  },
  computed: {
    enrollment_price () {
      if (this.school.enrollment_price) {
        return Number.parseFloat(this.school.enrollment_price);
      }
      return Number(0.00);
    },
    first_name () {
      return (this.$store.getters['studentStore/getName']);
    },
    last_name () {
      return (this.$store.getters['studentStore/getLastName']);
    },
    orderTotal () {
      if (!this.enrollmentKeyApplied) {
        if (this.school.enrollment_price) {
          return Number.parseFloat(this.school.enrollment_price);
        }
      }
      return Number(0.00);
    },
    months () {
      return PaymentService.months;
    },
    password () {
      return this.$store.getters['user/getPassword'];
    },
    plans () {
      return this.$store.getters['plans/getPlans'];
    },
    returnURL () {
      return this.$store.getters['params/getReturnURL'];
    },
    school () {
      return this.$store.getters['studentStore/getSelectedSchool'];
    },
    step: {
      get: function () {
        return this.$store.getters['studentStore/getStep'];
      },
      set: function (step) {
        this.$store.commit('studentStore/setStep', step);
      }
    },
    stripeCardToken: {
      get: function () {
        return this.$store.getters['studentStore/getStripeCardToken'];
      },
      set: function (stripeCardToken) {
        this.$store.commit('studentStore/setStripeCardToken', stripeCardToken);
      }
    },
    userOrigin () {
      return this.$store.getters['studentStore/getUserOrigin'];
    },
    years () {
      return PaymentService.years;
    },
    isFormDirty () {
      return Object.keys(this.veeFields).some(key => this.veeFields[key].dirty);
    }
  },
  watch: {
    stripeCardToken (newToken, oldToken) {
      if (newToken) {
        this.getOrCreateStripeCustomer(this.user, this.stripeCardToken);
      }
    }
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      this.incrementStep();
      this.addCustomValidator();
      if (this.isLTIUser) {
        this.$store.dispatch('plans/loadPlans');
        this.verifyBasicData();
      }
    },
    /**
     * Navigation can only flow forward
     * only increment the Store step if it is smaller than current
     */
    incrementStep () {
      if (this.step < 3) {
        this.step = 3;
      }
    },
    /**
     * Logs in specific user then redirects
     **/
    doLogin(email, password) {
      this.$store.dispatch('studentStore/doLogin', { username: email, password: password })
        .then(() => {
          this.redirectUrl(this.returnURL);
        });
    },
    /**
     * Wrap a very brittle method for extracting class id from the return url
     * Send error messages to Sentry
     */
    extractClassFromReturnURL(incomingCustomer){
      let classID = '';
      try {
        classID = this.returnURL.split('/list/')[1].split('/')[0];
      }
      catch {
        Sentry.captureMessage(`LTI MERGE ERROR: ${this.existingUserAccount.id} - ${incomingCustomer.id}`);
      }
      return classID;
    },
    /**
     * Collection point for a handful of data needed to merge LTI accounts
     **/
    dispatchMerge(incomingCustomer) {
      return new Promise( (resolve ,reject) => {
        this.$store.dispatch('studentStore/mergeLTIAccount',
                             {
                               existing_customer_id: incomingCustomer.id,
                               customer_to_replace: this.existingUserAccount.id,
                               alias_id: incomingCustomer.id,
                               organization_id: this.existingUserAccount.organization.id,
                               class_id: this.extractClassFromReturnURL(incomingCustomer)
                             })
          .then((response) => {
            resolve(response);
          })
          .catch(() => {
            reject(`Your account has failed to merge with your LMS. Please contact support, reference: ${this.existingUserAccount.id}-${incomingCustomer.id}`);
          });
      });
    },
    /**
     * Check if incoming LTI user/logged-in account:
     * a) has a plan_id of in the 'valid_plans' table
     * b) if a membership expiration date is provided (some internal accounts never expire), check if time is left on it
     * Then re-associate lti account with logged-in account, and re-direct (skipping payment)
     */
    processLTISubscription (validPlans) {
      if (this.user && this.user.membership && this.user.membership.plan_id) {
        if (Object.values(validPlans).indexOf(this.user.membership.plan_id) !== -1) {
          if (!this.user.membership.expired_at || ((this.user.membership.expired_at) &&
            (moment().diff(moment(this.user.membership.expired_at)) < 0))) {
            this.showFullPageLoading(true);
            this.dispatchMerge(this.user).then(() => {
              this.doLogin(this.email, this.password);
            });
          }
        }
      }
    },
    /**
     * Adds a custom Validator to Vee Validate
     */
    addCustomValidator () {
      this.$validator.extend('keyUnused', {
        validate: (value) => {
          return new Promise(resolve => {
            axios.get(this.SPENV.APP_API_URL + '/coupon/' + value, { withCredentials: true })
              .then(
                response => {
                  if (response.data && response.data.validCoupon) {
                    return resolve({
                      valid: true
                    });
                  } else {
                    return resolve({
                      valid: false
                    });
                  }
                },
                error => {
                  console.log(error);
                  return resolve({
                    valid: false,
                    data: {
                      message: 'Invalid key'
                    }
                  });
                }
              );
          });
        },
        getMessage: (field, params, data) => {
          // if there's a message from the api, use that
          if (data && data.message) {
            return data.message;
          } else {
            return 'Key could not be applied.';
          }
        }
      });
    },
    hasAlert() {
      return this.alertMessage ? true : false;
    },
    isCardPayment() {
      return this.purchaseType === 'card';
    },
    isEnrollmentKey() {
      return this.purchaseType === 'enrollment_key';
    },
    changePaymentType(type) {
      this.purchaseType = type;
      if (this.isCardPayment()) {
        this.removeEnrollmentKey();
      } else {
        this.removeCreditCard();
      }
      this.$validator.reset();
      this.alertMessage = null;
      this.errors.clear();
    },
    /**
     * Validate enrollment key field
     */
    validateEnrollmentKey () {
      this.showFullPageLoading(true);
      this.$validator.validate('enrollment_key').then((result) => {
        if (result) {
          this.enrollmentKeyApplied = true;
        } else {
          this.form.enrollmentKey = '';
        }
        this.showFullPageLoading(false);
      });
    },
    removeEnrollmentKey () {
      this.enrollmentKeyApplied = false;
      this.form.enrollmentKey = '';
    },
    /**
     * Removes all the values from the credit card form
     */
    removeCreditCard() {
      Object.keys(this.card).forEach(key => {
        this.card[key] = '';
      });
    },
    /**
     * Form submit handler
     * If Stripe customer, charge credit card
     * THEN: redirect to order success
     * NOTE: error handling is taken care of in each of the called functions
     * For enrollment keys: TODO
     * @param {Event} event
     * @return {Void}
     */
    submit (event) {
      event.preventDefault();
      this.showFullPageLoading(true);

      if (this.isCardPayment()) {
        if (!this.stripeUser) {
          this.handleOrderFailure('Please address card error before continuing');
        }
        // Make sure linking to Stripe account succeeded
        if (this.stripeUser && this.validatedStripeCard) {
          this.verifyCard(this.stripeUser, this.validatedStripeCard).then(() => {
            this.chargeCreditCard().then((cardCharged) => {
              this.mergeLTIAccounts().then(() => {
                this.handleOrderResponse(cardCharged);
              }).catch((error) => {
                this.handleOrderFailure(error);
              });
            });
          });
        }
      } else {
        this.submitEnrollmentKeyForm();
        this.showFullPageLoading(false);
      }
    },
    /**
     * If tokenized card from form is not Stripe User's active card, set it as active card
     *
     * IF fingerprints match, no need to update, resolve()
     *
     * @param {object} stripeUser -- valid Stripe User
     * @param {object} activeCard -- tokenized Stripe card (post form validation, from js validator)
     *
     * @return {Promise}
     */
    verifyCard (stripeUser, activeCard) {
      return new Promise((resolve, reject) => {
        if (!stripeUser.active_card || (stripeUser.active_card.fingerprint !== activeCard.card.fingerprint)) {
          this.updateStripeCustomer(this.stripeUser.id, 'source', this.validatedStripeCard.id)
            .then((response) => {
              if (response) {
                resolve();
              }
              reject();
            })
            .catch(e => {
              this.handleOrderFailure(e);
              reject(e);
            });
        } else {
          resolve();
        }
      });
    },
    /**
     * If the credit card form has all fields filled out, and they are valid
     * validate the card with Stripe's API
     *
     * NOTE: Will set tokenized card on store
     * @return {void}
     */
    validateCardForm (event) {
      event.preventDefault();
      if (this.isCardPayment()) {
        if (this.isFormFilled()) {
          this.showFullPageLoading(true);
          PaymentService.validateCreditCard({
            number: this.card.number,
            cvc: this.card.cvc,
            exp_month: this.card.exp_month,
            exp_year: this.card.exp_year
          }).then((response) => {
            if (response.error) {
              this.handleOrderFailure(response.error);
              // Process payment and enroll in membership
            } else {
              this.validatedStripeCard = response;
              this.stripeCardToken = response.id;
              this.$validator.reset();
            }
            this.showFullPageLoading(false);
          }).catch(e => {
            this.handleOrderFailure(e.message);
          });
        }
      }
    },
    /**
     * Iterate through the object keys on the credit card form
     * if one is still 'null', the field is pristine and the form has not been
     * completely filled out
     *
     * @return {boolean}
     */
    isFormFilled () {
      for (const field of Object.keys(this.card)) {
        if (this.card[field] == null || this.card[field] == '') {
          return false;
        }
      }
      return true;
    },

    /**
     * Wrapper to decide if customer is existing/new
     * Errors are handled in the called functions
     *
     * @param {object} customer --user/this.user/solidprofessor customer
     * @param {string} cardToken -- this.stripetoken/string tokenized card response from stripe API
     *
     * @resolve {object} stripe customer
     */
    getOrCreateStripeCustomer (customer, cardToken) {
      return new Promise((resolve, reject) => {
        if (customer.payment_processor && customer.payment_processor.toLowerCase() === 'stripe') {
          resolve(this.getExistingStripeCustomer(customer));
        } else {
          resolve(this.createStripeCustomer(customer, cardToken));
        }
      });
    },
    /**
     * Calls store to Update a singular field on a Stripe customer
     * Currently used to set the form card to the user's active card (but is field agnostic)
     *
     * @param {string} stripeId -- Stripe id of the customer to be update, i.e. 'cus_FfKige6uoYeEFy'
     * @param {string} updateKey -- plain text 'name' of the field to be updated, i.e. 'source' or 'email'
     * @param {string} updateValue -- the new value of the update
     *
     * @return {Promise}
     */
    updateStripeCustomer (stripeId, updateKey, updateValue) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('studentStore/updateStripeCustomer', {'stripeId': stripeId, 'updateKey': updateKey, 'updateValue': updateValue})
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            this.handleOrderFailure(error);
            reject(error);
          });
      });
    },
    /**
     * Get existing customer data from Stripe API
     *
     * @param {object} customer --user/this.user/solidprofessor customer
     *
     * @resolve {object} stripe customer
     * @reject {void} -- with proper error handling and form reset
     */
    getExistingStripeCustomer (customer) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('studentStore/getStripeCustomer', customer.id)
          .then(stripeCustomer => {
            this.showFullPageLoading(false);
            // Reset error state
            this.alertMessage = null;
            this.errors.clear();
            resolve(stripeCustomer);
          })
          .catch(error => {
            this.handleOrderFailure(error.response.data);
            reject(error);
          });
      });
    },
    /**
     * Register a new customer with Stripe (on their servers)
     *
     * @param {object} customer /user/customer/sp idcustomer to create a new account for
     * @param {string} cardToken tokenized identifier of card validated by form, this card will be tied to the
     *                            user account that is created. This token is encrypted an safe to transmit
     * @resolve {object} the new customer, just for validation sake. The 'dispatch' sets the result on the store
     */
    createStripeCustomer (customer, cardToken) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('studentStore/createStripeCustomer', {'customerId': customer.id, 'cardToken': cardToken})
          .then(newStripeCustomer => {
            this.showFullPageLoading(false);
            // Reset error state
            this.alertMessage = null;
            this.errors.clear();
            resolve(newStripeCustomer);
          })
          .catch(error => {
            this.handleOrderFailure(error.response.data);
            reject(error);
          });
      });
    },
    /**
     * Charges validated and linked card with current purchase through SP Legacy codeline
     * NOTE: all fields in `body` must be present
     *
     * @resolve {response object}
     * @reject {error} -- handles errors
     */
    chargeCreditCard () {
      return new Promise((resolve, reject) => {
        let body = {
          first_name: this.first_name ?? this.user.first_name,
          last_name: this.last_name ?? this.user.last_name,
          email: this.email,
          password: this.password,
          path: this.userOrigin,
          stripeToken: this.validatedStripeCard.id,
          adminid: this.school.rowid,
          stripeId: this.stripeUser.id
        };
        this.$store.dispatch('studentStore/chargeCreditCard', body)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            this.handleOrderFailure(error.response.data);
            reject(error);
          });
      });
    },
    /**
     * If email and password from previous form is not present, push backward to re-populate
     * @return {void}  -- pushes router
     */
    verifyBasicData () {
      if (!this.email || !this.password) {
        this.$router.push({ name: 'StudentAccount' });
      }
    },
    /**
     * Hits API for current list of acceptable plans
     * Calls 'processLTISubscription()' to determine if the LTI user needs
     *
     */
    validateMemberPlan () {
      let axiosConnectionData = {
        method: 'get',
        url: this.SPENV.APP_API_URL + '/validplans',
        body: {}
      };
      this.$store.dispatch('studentStore/connectToAPI', axiosConnectionData)
        .then(
          response => {
            this.processLTISubscription(response.data);
          },
          error => {
            this.handleOrderFailure(error.response.data);
          }
        );
    },
    /**
     * Post enrollment key to activate membership
     */
    submitEnrollmentKeyForm () {
      if (!this.enrollmentKeyApplied) {
        this.showFullPageLoading(false);
      } else {
        let axiosConnectionData = {
          method: 'post',
          url: this.SPENV.APP_API_URL + '/membership/key',
          body: {
            first_name: this.first_name ?? this.user.first_name,
            last_name: this.last_name ?? this.user.last_name,
            email: this.email,
            password: this.password,
            enrollmentKey: this.form.enrollmentKey,
            path: this.userOrigin,
            adminid: this.school.rowid
          }
        };
        this.$store.dispatch('studentStore/connectToAPI', axiosConnectionData)
          .then((response) => {
            this.mergeLTIAccounts().then(() => {
              this.handleOrderResponse(response);
            }).catch((error) => {
              this.handleOrderFailure(error);
            });
          });
      }
    },
    /**
     * 1. Loads fresh user data from the API
     * 2. Check their plan is valid (post payment)
     * 3. Merge good user account with temp LTI access account
     * 4. On success login and redirect
     **/
    mergeLTIAccounts() {
      return new Promise( (resolve ,reject) => {
        if (this.isLTIUser) {
          this.$store.dispatch('user/loadUserInfo').then((freshData) => {
            if (_.has(freshData, 'membership.plan_id')) {
              const membership = freshData.membership;
              const localUser = freshData;
              if (this.existingUserAccount.id != localUser.id) {
                if (_.find(this.plans, ['id', membership.plan_id])) {
                  if (!membership.expired_at || moment().diff(moment(membership.expired_at)) < 0) {
                    this.dispatchMerge(localUser).then((response) => {
                      resolve(true);
                    }).catch(error => {
                      this.handleOrderFailure(error);
                      reject(error);
                    });
                  }
                }
              }
            }
          });
        }
        resolve(true);
      });
    },
    /**
     * @param {object} response
     */
    handleOrderResponse (response) {
      // email customer
      this.postToSendgrid(response.data);

      // successful order - goto confirmation page
      let order = {
        id: response.data.idOrder,
        paymentMethod: this.purchaseType,
        total: this.orderTotal
      };
      this.$store.commit('studentStore/setOrder', order);
      this.showFullPageLoading(false);
      this.$router.push({ name: 'StudentConfirmation' });
    },
    /**
     * @param {object} error
     */
    handleOrderFailure (error) {
      this.showFullPageLoading(false);

      if (_.has(error, 'response.data')) {
        this.alertMessage = error.response.data;
      } else if (_.has(error, 'message')) {
        this.alertMessage = error.message;
      } else {
        this.alertMessage = error;
      }

      // If card issue add to the error bag
      if (_.has(error, 'type') && error.type === 'card_error') {
        this.errors.add({
          field: error.param,
          msg: this.alertMessage
        });
      }
    },
    /**
     * Sends out the reset password email. Called on a good response after reset information is logged in the DB
     *
     * TODO: Once the Laravel token dealer goes live, attach the tokens and send to `V3/email/send-dynamic`
     */
    postToSendgrid (order) {
      let axiosConnectionData = {
        method: 'post',
        url: this.SPENV.APP_API_URL + '/sendgrid/send-dynamic-email',
        headers: {'Content-Type': 'multipart/form-data', withCredentials: true, },
        body:
          {
            'template_id': this.sendgridEmailID,
            'user_email': this.email ? this.email : this.user.email,
            'substitutions':
              {
                'first_name': this.user.first_name,
                'last_name': this.user.last_name,
                'username': this.email ? this.email : this.user.email,
                'order_id': '' + order.idOrder,
                'order_date': this.formatOrderDate(order.orderDate),
                'interval': order.description,
                'email': this.email ? this.email : this.user.email,
                'payment_method': this.isCardPayment() ? 'Credit Card' : 'Enrollment Key',
                'payment_total': '' + this.orderTotal,
                'is_lti': this.isLTIUser.toString(),
              }
          }
      };
      this.$store.dispatch('studentStore/connectToAPI', axiosConnectionData);
    },
    formatOrderDate (rawDate) {
      let date = new Date(rawDate);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      return month + '/' + day + '/' + year;
    }
  }
};
</script>

<style scoped></style>
